import { AxiosError, AxiosResponse } from 'axios';

export function isAxiosResponseError(error: unknown): error is AxiosResponseError {
  return typeof error === 'object' && error !== null && 'response' in error;
}

export const hasAxiosErrorOnlyOneErrorMessage = (error: unknown): error is AxiosResponseError =>
  isAxiosResponseError(error) && typeof error.response.data.message === 'string';

export const hasAxiosErrorStatusText = (error: unknown): error is AxiosResponseError =>
  isAxiosResponseError(error) && !!error.response.statusText;

export type AxiosResponseError = AxiosError & { response: AxiosResponse };

export class NotFoundError extends Error {}
