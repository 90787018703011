import { ElementType } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { TToastProps } from './Toast.interface';
import { SmallToast } from '@elpassion/taco';

export const ErrorToast = ({
  icon,
  withCloseButton = true,
  ...rest
}: TToastProps) => {
  const ToastIcon = (icon || ExclamationCircleIcon) as ElementType;

  return (
    <SmallToast
      icon={<ToastIcon />}
      backgroundColor="bg-red-500"
      withCloseButton={withCloseButton}
      {...rest}
    />
  );
};
