import { CognitoError, CognitoErrorCode } from '@hub/cognito-auth';
import { useAuth } from '@hub/cognito-auth/AuthContext';
import { t } from '@lingui/macro';
import { errorToast, successToast } from '../../Toast';

type UseSocialLoginProps = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useSocialLogin = (
  { onError, onSuccess }: UseSocialLoginProps = {
    onSuccess: () => {
      successToast({
        title: t`Successfully logged in`,
      });
    },
    onError: () => {
      errorToast({
        title: t`Social login failed`,
      });
    },
  },
) => {
  const { cognitoApi } = useAuth();

  const internalSignIn = async (email: string, token: string) => {
    const user = await cognitoApi?.signInWithoutPassword(email);

    if (!user) {
      onError?.();
      return;
    }

    await cognitoApi?.sendCustomChallengeAnswer(token);
    onSuccess?.();
  }

  const mutate = async (email: string, token: string) => {
    try {
      await internalSignIn(email, token);
    } catch (error) {
      const cognitoError = error as CognitoError;
      if (cognitoError.name === CognitoErrorCode.UserAlreadyAuthenticatedException) {
        await cognitoApi?.signOut();
        await internalSignIn(email, token);
        return
      }

      onError?.();
      throw error;
    }
  };

  return {
    mutate,
  };
};
