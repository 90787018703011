import type { NextPage } from 'next';
import { Trans } from '@lingui/macro';
import { AuthLayout } from '../../CustomAuth/components';
import { CustomSignInWrapper } from '../../CustomAuth/CustomSignInForm';

export const CustomSignIn: NextPage = () => {
  const boxContent = (
    <>
      <h3 className="text-3xl mb-6 font-medium">
        <Trans>Welcome back!</Trans>&nbsp;&#128075;
      </h3>
      <p className="text-base">
        <Trans>Work Smarter, Not Harder</Trans>
        <br />
        <Trans>Unlock the Efficiency Power of EL Chrono.</Trans>
      </p>
    </>
  );

  return (
    <AuthLayout boxContent={boxContent}>
      <h2 className="text-3xl font-medium mb-2 text-neutral-800 text-center 2xl:text-left">
        <Trans>Login to EL Chrono</Trans>
      </h2>
      <p className="text-base text-neutral-500 mb-10 text-center 2xl:text-left">
        <Trans>Please enter your details below</Trans>
      </p>

      <CustomSignInWrapper />
    </AuthLayout>
  );
};
