import { useState } from 'react';
import { AddonsContentVariants } from '@elpassion/taco';
import { t, Trans } from '@lingui/macro';
import { nestJsZodResolver } from 'nestjs-zod-hookform-resolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSignIn } from '@hub/cognito-auth/Auth.hooks';
import { CognitoAppErrorMessages } from '@hub/cognito-auth/Auth.interface';
import { signInSchema, TSignIn } from '@hub/contracts';
import { useBoolean } from '@hub/ts-utils';
import { Divider, Link } from '@hub/ui';
import { routes } from '../../../utils';
import { getCognitoTranslatedErrorText } from '../../../utils/translateUtils';
import { Column, Field, Form, InputContainer, Submit } from '../../Form';
import { InputField } from '../../Inputs';
import { ChooseTenantModal } from '../../Tenants/components';
import { GoogleButton } from '../components/GoogleButton';
import { ConfirmSignUpForm } from '../ConfirmSignUpForm';
import { useTogglePasswordVisibility } from '../hooks/useTogglePasswordVisibility';

interface ICustomSignInFormProps {
  onSuccess: () => void;
  onAccountNotConfirmedError: (data: TSignIn) => void;
}

const CustomSignInForm = ({ onSuccess, onAccountNotConfirmedError }: ICustomSignInFormProps) => {
  const { signIn, isProcessing } = useSignIn({
    onSuccess,
  });

  const methods = useForm<TSignIn & { global: string }>({
    resolver: nestJsZodResolver(signInSchema),
    defaultValues: {
      mail: '',
      password: '',
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  const { getPasswordIcon, isPasswordVisible } = useTogglePasswordVisibility();

  const onSubmit: SubmitHandler<TSignIn> = async data =>
    await signIn({ mail: data.mail, password: data.password }).catch(error => {
      const errorText = getCognitoTranslatedErrorText(error);

      if (errorText === t`${CognitoAppErrorMessages.UserNotConfirmed}`)
        onAccountNotConfirmedError(data);

      setError('global', {
        message: errorText,
      });
    });

  return (
    <Form
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      globalError={errors.global}
      submitButton={<Submit text={t`Sign In`} isFluid isLoading={isProcessing} />}
      className="mb-4"
    >
      <Field name="mail">
        <Column>
          <InputContainer>
            <InputField placeholder={t`Enter your email`} type="email" label={t`Email`} required />
          </InputContainer>
        </Column>
      </Field>

      <Field name="password">
        <Column>
          <InputContainer>
            {/*TODO Input doesnt take suffix icon as a prop for now*/}
            <InputField
              placeholder={t`Enter your password`}
              type={isPasswordVisible ? 'text' : 'password'}
              label={t`Password`}
              suffixProps={{
                variant: AddonsContentVariants.ICON,
                icon: getPasswordIcon(),
              }}
              required
            />
          </InputContainer>
        </Column>
      </Field>

      <div className="flex items-center justify-between mt-2 mb-10">
        <Link href={routes.forgotPassword} prefetch={false}>
          <Trans>Forgot your password?</Trans>
        </Link>
      </div>
    </Form>
  );
};

export const CustomSignInWrapper = () => {
  const [newRegisteredUser, setNewRegisteredUser] = useState<TSignIn>();

  const [chooseTenantModalOpened, openChooseTenantModal] = useBoolean();

  if (newRegisteredUser)
    return (
      <ConfirmSignUpForm
        email={newRegisteredUser.mail}
        onAccountConfirmed={() => setNewRegisteredUser(undefined)}
        error={{ message: t`${CognitoAppErrorMessages.UserNotConfirmed}` }}
      />
    );

  return (
    <div className="w-full">
      {chooseTenantModalOpened && <ChooseTenantModal />}

      <CustomSignInForm
        onSuccess={openChooseTenantModal}
        onAccountNotConfirmedError={setNewRegisteredUser}
      />

      <Divider type="horizontal" label={t`OR`} className="mb-4" />

      <GoogleButton onSignInSuccess={openChooseTenantModal} />

      <p className="text-sm mt-6 text-neutral-400 text-center">
        <Trans>No account?</Trans>
        <Link href={routes.signUp} className="font-medium ml-2" prefetch={false}>
          <Trans>Sign up</Trans>
        </Link>
      </p>
    </div>
  );
};
