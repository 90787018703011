import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TGetUser, TGoogleLogin, TGoogleLoginResponse, TSignUpWithCaptcha } from '@hub/contracts';
import { AuthApi } from './AuthApi';

export const userAuthApi = () => {
  const authApi = new AuthApi();

  const useRegisterAccount = ({
    onSuccess,
    onError,
  }: UseMutationOptions<void, AxiosError, TSignUpWithCaptcha> = {}) =>
    useMutation<void, AxiosError, TSignUpWithCaptcha>(data => authApi.signUp(data), {
      onSuccess,
      onError,
    });

  const useGoogleLogin = ({
    onSuccess,
    onError,
  }: UseMutationOptions<TGoogleLoginResponse, AxiosError, TGoogleLogin> = {}) =>
    useMutation<TGoogleLoginResponse, AxiosError, TGoogleLogin>(
      data => {
        return authApi.googleLogin(data);
      },
      {
        onSuccess,
        onError,
      },
    );

  return {
    useRegisterAccount,
    useGoogleLogin,
  };
};
