import { headerKey } from '@hub/captcha-utils';
import { TGetUser, TGoogleLogin, TGoogleLoginResponse, TSignUpWithCaptcha } from '@hub/contracts';
import {
  createHttpClientWithTranslatedErrors,
  HttpClientWithTranslatedErrors,
} from '../../../utils/HttpClientWithTranslatedErrors';

export class AuthApi {
  baseUrl = '/api';
  private client: HttpClientWithTranslatedErrors;

  constructor() {
    this.client = createHttpClientWithTranslatedErrors();
  }

  async signUp(data: TSignUpWithCaptcha): Promise<void> {
    await this.client.post<TSignUpWithCaptcha, void>(this.baseUrl + '/registration', data, {
      headers: {
        [headerKey]: data.gReCaptchaToken,
      },
    });
  }

  async googleLogin(data: TGoogleLogin): Promise<TGoogleLoginResponse> {
    return await this.client.post(this.baseUrl + '/external-login/google', data);
  }
}
