import { ErrorToast, InfoToast, SuccessToast, TToastProps, WarningToast } from '@hub/ui';
import { ToastOptions, toast } from 'react-hot-toast';
import { i18n } from '@lingui/core';

type IToastProps = {
  options?: Pick<ToastOptions, 'duration' | 'position' | 'id'>;
} & Partial<TToastProps>;

export const errorToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: i18n._('Error'),
    ...props,
  };

  return toast(t => <ErrorToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />, {
    className: 'custom-toast',
    ...options,
  });
};

export const successToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: i18n._('Success'),
    ...props,
  };

  return toast(t => <SuccessToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />, {
    className: 'custom-toast',
    ...options,
  });
};

export const infoToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: i18n._('Info'),
    ...props,
  };

  return toast(t => <InfoToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />, {
    className: 'custom-toast',
    ...options,
  });
};

export const warningToast = (props?: IToastProps) => {
  const { title, options, ...rest } = {
    title: i18n._('Warning'),
    ...props,
  };

  return toast(t => <WarningToast title={title} onClose={() => toast.dismiss(t.id)} {...rest} />, {
    className: 'custom-toast',
    ...options,
  });
};
