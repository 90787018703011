import { ElementType } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { TToastProps } from './Toast.interface';
import { SmallToast } from '@elpassion/taco';

export const SuccessToast = ({ icon, withCloseButton = true, ...rest }: TToastProps) => {
  const ToastIcon = (icon || CheckCircleIcon) as ElementType;

  return (
    <SmallToast
      icon={<ToastIcon />}
      backgroundColor="bg-green-500"
      withCloseButton={withCloseButton}
      {...rest}
    />
  );
};
