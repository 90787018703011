import { useRouter } from 'next/router';
import { t } from '@lingui/macro';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { userAuthApi } from '../../Api/Auth';
import { errorToast, successToast } from '../../Toast';
import { useSocialLogin } from '../hooks/useSocialLogin';

type GoogleButtonProps = {
  text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signin';
  onSignInSuccess?: () => void;
};

export const GoogleButton = ({ text, onSignInSuccess }: GoogleButtonProps) => {
  const router = useRouter();
  const { mutate: signInWithGoogle } = useSocialLogin({
    onSuccess: () => {
      successToast({
        title: t`Successfully logged in`,
      });

      if (onSignInSuccess) onSignInSuccess();
    },
  });
  const { useGoogleLogin } = userAuthApi();
  const { mutateAsync: googleLoginMutateAsync } = useGoogleLogin();

  const handleSignWithGoogle = async (credentialResponse: CredentialResponse) => {
    try {
      const { token, email } = await googleLoginMutateAsync({
        credential: credentialResponse.credential as string,
      });

      await signInWithGoogle(email, token);
    } catch (error) {
      errorToast({
        title: t`You can not login using this Google Account`,
      });
    }
  };

  return (
    <GoogleLogin
      locale={router.locale}
      text={text}
      width="340px"
      containerProps={{
        className: 'mb-2',
      }}
      onSuccess={handleSignWithGoogle}
      onError={() => {
        errorToast({
          title: t`Google login failed`,
        });
      }}
      useOneTap
    />
  );
};
