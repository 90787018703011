import { useState } from 'react';

type SetTrue = () => void;

type SetFalse = () => void;

type Toggle = () => void;

export const useBoolean = (defaultValue = false): [boolean, SetTrue, SetFalse, Toggle] => {
  const [isTrue, setIsTrue] = useState<boolean>(defaultValue);

  const setTrue = () => setIsTrue(true);

  const setFalse = () => setIsTrue(false);

  const toggle = () => setIsTrue(prev => !prev);

  return [isTrue, setTrue, setFalse, toggle];
};
