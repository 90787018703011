import { HttpClient, IHttpClientOptions } from '@hub/http-client';
import { getEnvVariables } from '@hub/next-utils';
import { i18n } from '@lingui/core';
import { AxiosError } from 'axios';
import {
  hasAxiosErrorOnlyOneErrorMessage,
  hasAxiosErrorStatusText,
} from '../modules/Api/Errors/AxiosResponseError';

export class HttpClientWithTranslatedErrors extends HttpClient {
  private static translatedInstance: HttpClientWithTranslatedErrors;
  constructor(options: IHttpClientOptions) {
    super({
      url: options.url,
      onErrorInterceptor: (error: AxiosError) => this.translateErrorMessage(error),
    });
  }

  public static getInstance(options: IHttpClientOptions): HttpClientWithTranslatedErrors {
    if (!HttpClientWithTranslatedErrors.translatedInstance) {
      HttpClientWithTranslatedErrors.translatedInstance = new HttpClientWithTranslatedErrors(
        options,
      );
    }
    return HttpClientWithTranslatedErrors.translatedInstance;
  }

  private translateErrorMessage(error: AxiosError): AxiosError {
    if (hasAxiosErrorOnlyOneErrorMessage(error)) {
      error.response.data.message = i18n._(error.response?.data.message);
      return error;
    }
    if (hasAxiosErrorStatusText(error)) {
      error.response.statusText = i18n._(error.response.statusText);
      return error;
    }
    return error;
  }
}

export const createHttpClientWithTranslatedErrors = () => {
  return HttpClientWithTranslatedErrors.getInstance({ url: getEnvVariables().PAGE_URL });
};
